import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import qs from 'query-string';
import axios from 'axios';

import Layout from '../components/layout/Layout';

import ChangePasswordForm from '../components/ChangePassword/ChangePasswordForm';
import SenderIllustration from '../components/common/SenderIllustration';
import LoadingScreen from '../components/layout/LoadingScreen';

function validatePasswordToken({ token }) {
	return axios.get(
		`/.netlify/functions/change-password-validate?${qs.stringify({
			token,
		})}`
	);
}

export default function ChangePasswordPage({ data, location }) {
	const [isLoaded, setLoaded] = useState(false);

	const { token } = qs.parse(location.search);

	useEffect(() => {
		const validate = async () => {
			if (!token) {
				navigate('/error');
			}
			try {
				const response = await validatePasswordToken({ token });

				if (response.data.status === 'expired') {
					navigate('error', { state: { type: 'tokenExpired' } });
				}

				setLoaded(true);
			} catch (e) {
				navigate('/error');
			}
		};

		validate();
	}, []);

	if (!isLoaded) {
		return <LoadingScreen loading={data.loading} />;
	}

	return (
		<Layout footerImg={data.logo_footer}>
			<section id="change-password-form" className="pb-0 bg-white">
				<div className="container">
					<div className="row p-sm-5">
						<div className="col-12 col-sm-6">
							<h2 className="text-center text-sm-left my-4 mt-sm-0 ml-md-0">
								Üdv! Itt tudsz új jelszót létrehozni.
							</h2>
							<ChangePasswordForm token={token} />
						</div>
						<div className="col-12 col-sm-6 d-none d-sm-block text-center pt-5">
							<SenderIllustration />
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}

ChangePasswordPage.propTypes = {
	/* eslint-disable-next-line react/forbid-prop-types */
	data: PropTypes.object.isRequired,
	location: PropTypes.shape({
		search: PropTypes.string.isRequired,
	}).isRequired,
};

export const query = graphql`
	{
		logo_footer: file(relativePath: { eq: "logo_footer.png" }) {
			childImageSharp {
				fixed(width: 92, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		loading: file(relativePath: { eq: "loading.png" }) {
			childImageSharp {
				fluid(maxWidth: 350, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;
